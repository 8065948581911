
.AddNote {
  color: #dedede;
}

.AddNote h2 {
  margin-left: 16px;
}

button:disabled {
  background-color: red;
}
